/*PROPERTIES*/
/*HOME/LOGIN PAGES*/
.auth-fluid{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background: url("../../styles/images/background.jpg") center;
  background-size: cover;
}

.auth-fluid .auth-fluid-form-box {
    max-width: 480px;
    border-radius: 0;
    z-index: 2;
    padding: 3rem 2rem;
    background-color: #fff;
    position: relative;
    width: 100%;
}

.h-100 {
    height: 100%!important;
}

.align-items-center {
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
}

.auth-fluid .auth-fluid-right {
    padding: 6rem 3rem;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
    color: #fff;
    background-color: rgba(49,58,70,.6);
}

.auth-user-testimonial {
    position: absolute;
    margin: 0 auto;
    padding: 0 1.75rem;
    top: 3rem;
    left: 0;
    right: 0;
}

.text-muted2 {
    color: #98a6ad!important;
    font-family: 'Open Sans' !important;
}

.close {
  display: none;
}

.dropzone {
  border: 2px dashed rgba(49,58,70,.3);
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  min-height: 150px;
  padding: 20px;
}

.dropzone .dz-message{
  text-align: center;
  margin: 2rem 0;
  color: #6c757d;
  font-size: 40px;
}

::placeholder {
  color: #ced4da !important;
}

.header-left {
  margin-top: 1em;
  margin-left: 0.5em; }

.hero-text-container {
  padding-bottom: 4em;
  margin: auto; }
  .hero-text-container img.main-hero-image, .hero-text-container img.main-hero-image:after {
    zoom: 50%; }
  .hero-text-container .thanks-main-title {
    color: #0000ff;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    padding: 0 1em 0 1em; }
    .hero-text-container .thanks-main-title h1 {
      text-shadow: none;
      font-size: 9vmax;
      line-height: 1em; }
    .hero-text-container .thanks-main-title .intro-button-wrapper {
      text-align: center; }
  .hero-text-container .login-main-subtitle, .hero-text-container .thanks-main-subtitle {
    color: white;
    text-align: center;
    font-size: 6vmax;
    font-family: "Roboto", sans-serif;
    font-weight: 600; }
  .hero-text-container .login-main-subtitle {
    padding: 0 1em 0 1em; }
  .hero-text-container .thanks-main-subtitle {
    padding: 0 0.5em 0 0.5em; }

.main-container-login .btn-info-icon {
  background: none;
  float: right;
  border: none;
  outline: none; }
  .main-container-login .btn-info-icon #info-icon {
    height: 2em; }

.main-container-login .button-content-container {
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .main-container-login .button-content-container h2 {
    color: white;
    float: left;
    font-family: "Roboto", sans-serif;
    line-height: initial;
    margin-bottom: 0;
    margin-right: 0.5em; }
  .main-container-login .button-content-container .button-image {
    float: left; }

.main-container-login .number-button {
  color: #000000;
  float: left;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  margin-bottom: 0;
  width: 100%;
  background-color: transparent;
  border: none;
  outline-width: 0px; }

.main-container-login .button-container-login-number {
  position: fixed;
  bottom: 5.5em; }

.main-container-login .button-content-container-number {
  display: flex;
  flex-direction: row;
  justify-content: center; }

.main-container-login .button-item-container-number {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 5.5em;
  height: 3.1em; }

.main-container-login .button-container-login {
  position: fixed;
  bottom: 0; }

.main-container-login .button-item-container {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 5.5em; }

.lock-background {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
  overscroll-behavior-y: none; }

#scratch-image {
  width: 280px;
  height: 380px; }

.flex-container-home .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4em;
  height: 4em; }

.flex-container-home .loader hr {
  border: 0;
  margin: 0;
  width: 40%;
  height: 40%;
  position: absolute;
  border-radius: 50%;
  animation: spin 2s ease infinite; }

.flex-container-home .loader :first-child {
  background: rgba(0, 0, 255, 0.9);
  animation-delay: -1.5s; }

.flex-container-home .loader :nth-child(2) {
  background: rgba(0, 0, 255, 0.9);
  animation-delay: -1s; }

.flex-container-home .loader :nth-child(3) {
  background: rgba(0, 0, 255, 0.9);
  animation-delay: -0.5s; }

.flex-container-home .loader :last-child {
  background: rgba(0, 0, 255, 0.9); }

@keyframes spin {
  0%, 100% {
    transform: translate(0); }
  25% {
    transform: translate(160%); }
  50% {
    transform: translate(160%, 160%); }
  75% {
    transform: translate(0, 160%); } }

.flex-container-home .scratch-container-home {
  width: 50vmax !important;
  max-width: 280px; }
  .flex-container-home .scratch-container-home .scratch-image-container .scratch-image {
    width: 100%;
    height: 100%; }
    .flex-container-home .scratch-container-home .scratch-image-container .scratch-image .ScratchCard__Container {
      width: 100% !important; }
      .flex-container-home .scratch-container-home .scratch-image-container .scratch-image .ScratchCard__Container .ScratchCard__Canvas {
        width: 100% !important;
        height: 100% !important; }

.flex-container-home .flex-header-home .header-right .btn-logout {
  background-color: transparent;
  background-image: none;
  border-color: #0000ff;
  color: #0000ff;
  font-family: "Roboto", sans-serif;
  margin-top: 1em;
  margin-right: 0.5em; }

.flex-container-home .flex-content-container-home .intro-container-home, .flex-container-home .flex-content-container-home .thanks-container-home {
  margin: auto;
  padding-bottom: 4em; }

.flex-container-home .scratch-container-home {
  margin: 2em auto auto; }
  .flex-container-home .scratch-container-home .scratch-header-home h2 {
    font-family: "Roboto", sans-serif;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 2em;
    line-height: 1; }

.flex-container-home .button-wrapper {
  display: flex;
  justify-content: center; }

.flex-container-home .button-item-container {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 4em; }
  .flex-container-home .button-item-container .btn-scratcher {
    border-radius: 0 !important;
    bottom: 0;
    font-size: 1.8em;
    font-weight: 800;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    font-family: "", sans-serif;
    color: #000000;
    background-color: #0000ff;
    text-transform: uppercase; }

/*MODAL*/
#react-confirm-alert .react-confirm-alert {
  margin: 0 1em 0 1em; }

#react-confirm-alert .react-confirm-alert h1 {
  font-family: "", sans-serif;
  color: #000000;
  margin-bottom: 10px;
  text-shadow: none; }

#react-confirm-alert .react-confirm-alert-button-group {
  justify-content: center; }

#react-confirm-alert .react-confirm-alert-button-group button {
  font-size: 1.8em;
  font-weight: 800;
  height: 2em;
  width: 80%;
  border-radius: 6px;
  font-family: "", sans-serif;
  color: #000000;
  background-color: #0000ff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }

#react-confirm-alert .react-confirm-alert-overlay {
  background: rgba(255, 255, 255, 0.3); }

#react-confirm-alert .react-confirm-alert-overlay {
  z-index: 1; }

#react-confirm-alert h3 {
  font-family: "Roboto", sans-serif;
  font-size: 1.3em;
  text-align: center;
  font-weight: 300;
  color: #000000;
  line-height: 1.3em; }

.loading-screen{
  background: rgba(0,0,0,.5) url('../images/ajax-loader.gif') center no-repeat;
  width:100%;
  height:100%;
  position:fixed;
  top:0;
  left:0;
  z-index:99999;
}

.modal {
  display: flex !important;
  vertical-align: middle; }
  .modal .modal-dialog {
    margin: auto; }
  .modal .modal-header {
    border-bottom: none;
    display: flex; /* Enable flexbox */
    justify-content: space-between; /* Align children to the start and end of the container */
    align-items: center; /* Center children vertically */
    padding: 2em; /* Adjusted padding */ }
    .modal .modal-header .modal-title {
      display: inline-block;
      color: #000000;
      font-family: "Roboto", sans-serif;
      flex-grow: 1; /* Allows the title to take up any available space */
      text-align: center; /* Centers the text within the title */}
  .modal .modal-body {
    padding: 0;
    font-family: "Roboto", sans-serif; }
    .modal .modal-body .question-box.question-form {
      border: none;
      background-color: #fff;
      padding-top: 0;
      padding-bottom: 2em; }
      .modal .modal-body .question-box.question-form .form-control {
        border: 1px solid #dee2e6;
        height: 3em; }
      .modal .modal-body .question-box.question-form .btn.btn-default.btn-admin {
        font-family: "", sans-serif;
        background: #0000ff;
        color: #000000;
        width: 65%;
        height: 3em;
        border: none; }
