.header-api {
  font-family: 'Roboto' !important;
  font-weight: bolder;
  font-size: 20px;
}

.body-api {
  padding: 5px;
  font-family: 'Roboto' !important;
  font-weight: normal;
  font-size: 12px;
  border: 1px solid lightgray;
  background-color: lightgray;
}

.section-tandem {
  margin: 20px;
}
